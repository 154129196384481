@import url("https://fonts.googleapis.com/css2?family=Commissioner:wght@400;500;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;


@layer components {
  .header-link {
    @apply text-white text-xs;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.burger {
  font-size: 8px;
}
